import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { map, tap } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';

import { Response } from '../../system/models/api';
import { User } from '../../system/models/user';
import { LoginCredentials, PasswordResetRequest } from '../models';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  httpClient = inject(HttpClient);

  public cookie?: string

  constructor(private router: Router, private messageService: MessageService) { }

  login(data: LoginCredentials) {
    return this.httpClient.post<Response<User>>(`${environment.apiUrl}/system/authentication/login`, data, {
      observe: 'response',
      withCredentials: true,
    })
      .pipe(map(httpResponse => {
        if (httpResponse.headers) {
          //this.cookie = httpResponse.headers.get('Set-Cookie') || undefined;
          //console.log(httpResponse.headers)
        } else {
          //console.log("No headers?")
        }
        return httpResponse.body;
      }))
      .pipe(tap((response) => {
        if (response && response!.success) {
          const user = response!.result!
          localStorage.setItem('authUser', JSON.stringify(user));
          console.log('Logged in as:');
          console.log(user);
        }
      }));
  }

  logout() {
    console.log("removing 'authuser': " + localStorage.getItem('authUser'))
    localStorage.removeItem('authUser');
    this.router.navigateByUrl('/login', {

    });// .navigate(['/']);
  }

  resetPassword(data: PasswordResetRequest) {
    return this.httpClient.post<Response<User>>(`${environment.apiUrl}/system/authentication/reset-password`, data, {
      observe: 'response'
    }).pipe(tap({
      error: (err) => {
          switch (err.status) {
              case 404:
                  this.messageService.add({
                      closable: true,
                      severity: 'error',
                      summary: 'Ongeldige reset code',
                      detail: 'De opgegeven reset code is ongeldig.',
                  })
                  break;
              case 406:
                console.log(err)
                  this.messageService.add({
                      closable: true,
                      severity: 'error',
                      summary: 'Zwak paswoord',
                      detail: 'Het voorgestelde paswoord is te zwak.',
                  })
                  break;
              default:
                throw err
                  console.log(err)
                  this.messageService.add({
                      closable: true,
                      severity: 'error',
                      summary: 'Onverwachte fout',
                      detail: 'Je hebt niet de machtiging om de gevraagde actie uit te voeren.',
                  })
                  break;
          }
      }
    }))
      .pipe(map(httpResponse => {
        return httpResponse.body
      }))
  }

  isLoggedIn() {
    return localStorage.getItem('authUser') !== null;
  }

  getCurrentUser(): User {
    const user = JSON.parse(localStorage.getItem('authUser')!) as User
    return user;
  }

  getCookie() {
    return this.cookie;
  }
}
